.Introduction .tagline {
  font-size: 2em;
}

.Introduction p {
  margin: 20px;
}

.Introduction .career {
  text-align: left;
}

.Introduction .careerContainer {
  display: flex;
  justify-content: center;
}